/* ======= GENERAL ======= */
:root {
  --super-dark: #613f49;
  --dark: #845d68;
  --darkTransparent: #845d68aa;
  --medium: #ce869b;
  --light: #efabbf;
  --super-light: #FFE3EC;
  --white: #fff;
  --header-height: 5rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

html {
  scroll-behavior: smooth
}

body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  background-color: #ffe3ec;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border-radius: 10px;
}

a {
  text-decoration: none
}

ul {
  list-style: none
}

main {
  padding-top: var(--header-height)
}

section {
  position: relative;
  height: 90vh;
  min-height: 650px;
  padding: 7vh 7vw;
  scroll-margin-top: var(--header-height);
  background-size: cover;
  background-position: center;
  overflow: initial;
}

/* ======= HEADER ======= */
header {
  position: fixed;
  padding-inline: 2vw;
  width: 100vw;
  min-height: 0;
  height: var(--header-height);
  background-color: var(--white);
  z-index: 50;
}

header #b_navbar {
  display: none;
  padding: .6rem 1rem;
  color: #fff;
  background-color: var(--light);
  border-radius: .6rem;
  font-size: 1.6rem;
  cursor: pointer;
  border: 3px solid var(--super-light);
  transition: all 0.2s ease;
  margin-right: .8rem;
  max-height: 80%;
}

header #b_navbar.active {
  background-color: var(--medium);
}

header>a {
  font-size: max(7vh, 2rem);
  line-height: 0;
  margin-bottom: -.6rem;
}

header nav ul li {
  margin-inline: 3rem 2rem;
  position: relative;
}

header nav ul li svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 160%;
  z-index: -1;
  transition: transform 0.1s ease;
}

header nav ul li:hover svg {
  transform: translate(-50%, -50%) scale(1.05);
}

header nav ul li svg path {
  transition: fill 0.1s ease;
}

header nav ul li:hover svg path {
  fill: var(--light);
}

header nav .select {
  position: relative;
  background: var(--super-light);
  border-radius: 10px;
}

header nav .select i {
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  font-size: 1rem;
  pointer-events: none;
}

header nav .select i.chevron {
  right: .6rem;
}

header nav .select i.earth {
  left: .7rem;
}

header nav select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  padding: .6rem 2rem .6rem 2.2rem;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  cursor: pointer;
  color: var(--dark);
}

header nav select::-ms-expand {
  display: none;
}

header nav select option {
  background-color: var(--super-light);
}

/* ======= ACCUEIL ======= */
#home {
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr 1fr;
  -moz-column-gap: 2vw;
       column-gap: 2vw;
  padding-bottom: 7rem !important;
  background-image: url('../images/bg-accueil.webp');
}

.photo-container {
  grid-column: 2;
  grid-row: 1 / 3;
  width: 100%;
  height: 100%;
}

.photo {
  max-width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.title {
  margin: 1vh 0 max(4vh, 40px);
  grid-column: 1;
  grid-row: 1;
}

.title h1 {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  height: 12rem;
}

.title span {
  grid-row: 1;
  grid-column: 1;
  padding-top: .5rem;
  line-height: 6rem;
}

.title span:nth-child(2) {
  grid-row: 2;
}

.title img {
  height: 14rem;
}

#home .text {
  grid-row: 2;
  grid-column: 1;
}

.arrow {
  background-color: var(--white);
  border-radius: 2rem;
  position: absolute;
  width: 4rem;
  height: 4rem;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  transition: transform .1s ease;
}

.arrow:hover {
  transform: translate(-50%, -3px);
}

/* ======= MON TRAVAIL ======= */
#work {
  background-image: url("../images/bg-travail.webp")
}

#work img {
  width: 40%;
}

#work .text {
  width: 49%
}

#work h2 {
  line-height: 6rem;
  text-align: right;
  width: 100%;
}

/* ======= GALERIE ======= */
#gallery {
  background-image: url("../images/bg-galerie.webp")
}

#gallery h2 {
  line-height: 1.2;
  margin-bottom: 2vh;
}

#gallery .galerie {
  height: 80%;
  align-content: flex-start;
  overflow-x: auto;
  padding: 2vh 1vw 3vh 1vw;
  gap: 0 1vw;
}

#gallery .galerie img {
  height: 48%;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  border: 3px solid var(--white);
  cursor: pointer;
  z-index: 1;
  transition: transform 0.2s ease;
}

#gallery .galerie img:hover {
  transform: scale(1.05);
}

#gallery .galerie::-webkit-scrollbar {
  background-color: var(--super-light);
  border-radius: 10px;
}

#gallery .galerie::-webkit-scrollbar-thumb {
  background-color: var(--medium);
  border: 3px solid var(--super-light);
  border-radius: 10px;
}

/* ======= CONTACT ======= */
#contact {
  background-image: url("../images/bg-contact.webp");
  padding-inline: 3vw;
}

#contact h2 {
  margin-top: -6vh;
  margin-bottom: -3vh;
}
#contact p {
  margin-bottom: 1vh;
}

.contact-main form {
  width: 60%;
}

.contact-main input, .contact-main textarea {
  display: inline-block;
  width: 100%;
  border: none;
  margin-block: 1vh;
  padding: 1vh 1vw;
  border-radius: 14px;
  box-shadow: 0 0 0 1px #fff4, 0 0 5px 1px #0001;
  transition: box-shadow 0.1s ease;
}

.contact-main input:invalid:not(:placeholder-shown):not(:focus) {
  box-shadow: 0 0 0 4px #FF000044, 0 0 5px 1px #0001;
}

.contact-main input::-webkit-input-placeholder, .contact-main textarea::-webkit-input-placeholder {
  color: var(--darkTransparent);
}

.contact-main input::placeholder,
.contact-main textarea::placeholder,
.contact-main input::-webkit-input-placeholder,
.contact-main textarea::-webkit-input-placeholder,
.contact-main input::-moz-placeholder,
.contact-main textarea::-moz-placeholder {
  color: var(--darkTransparent);
}

.contact-main textarea {
  height: 40vh;
  resize: none;
}

.contact-main input:focus, .contact-main textarea:focus {
  box-shadow: 0 0 0 4px #FFE3EC70, 0 0 5px 1px #0001;
  outline: none;
}

.contact-main button.submit {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  margin: 3vh auto 0 auto;
}

.contact-main button.submit span, .contact-main button.submit i {
  position: relative;
  z-index: 2;
  color: #fff;
}

.contact-main button.submit svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 140%;
  z-index: 1;
  transition: transform .1s ease;
  max-width: 100%;
}

.contact-main button.submit:hover svg {
  transform: translate(-50%, -50%) scale(1.05);
}

.contact-main button.submit svg path {
  transition: fill .1s ease;
}

.contact-main button.submit:hover svg path {
  fill: var(--super-dark);
}

.contact-main input.half {
  width: 49%;
}

.other-contact {
  width: 38%;
}

.other-contact span {
  margin-top: 4vh;
  font-weight: bold;
}

.other-contact span i {
  font-size: 2em;
  margin-right: 1vw;
  width: 5vw;
  text-align: center;
}

.other-contact span a {
  text-decoration: underline;
}

.other-contact span a:active {
  color: var(--dark);
}

/* ======= FOOTER ======= */
footer {
  background-color: var(--super-light);
  padding-block: 2vh;
  text-align: center;
}

/* ======= POP-UP ======= */
.popup {
  padding: 3.5vh 1.5vw !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 700px !important;
}

.popup-title {
  padding: 0 !important;
  margin: 2vh 0 0 0 !important;
}

.popus-desc {
  padding-inline: 0 !important;
  margin-inline: 0 !important;
}

.popup-image {
  width: 400px;
  max-height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0 auto !important;
}

.popup-button {
  background-color: var(--medium) !important;
}

.popup-button:focus {
  box-shadow: 0 0 0 4px var(--super-light) !important;
}

/* ======= TABLETS ======= */
@media screen and (max-width: 1200px) {
  #home {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    -moz-column-gap: 0;
         column-gap: 0;
    padding-block: 5vh 15vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 0;
  }

  .title {
    grid-row: 1;
    grid-column: 1;
  }

  #home .text {
    grid-row: 2;
    grid-column: 1 / 3;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .photo-container {
    grid-row: 1;
    min-height: 10vh;
  }
  
  #work {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-block: 2vh;
  }

  #work img {
    display: none;
  }

  #work .text {
    width: 100%;
  }

  #work .text h2 {
    text-align: center;
    line-height: 1;
    margin-bottom: 1vh;
  }

  #gallery {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 0;
  }

  #gallery .galerie {
    height: 60vh;
    margin-block: 2vh;
  }

  #gallery h2 {
    text-align: center;
  }

  #contact {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 0;
  }

  #contact h2 {
    margin-bottom: 0;
  }

  #contact .contact-main {
    flex-direction: column;
  }

  .contact-main form {
    width: 100%;
  }

  .contact-main textarea {
    height: 30vh;
    min-height: 300px;
  }

  .other-contact {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    width: 100%;
    margin-bottom: 1vh;
  }

  .other-contact span {
    margin: 1vh 2vw;
    justify-content: center;
  }

  .other-contact span i {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

/* ======= PHONES ======= */
@media screen and (max-width: 1000px) {

  header {
    transform-style: preserve-3d;
  }

  header>a {
    font-size: 3rem;
    margin-left: 4vw;
  }

  header #b_navbar {
    display: block;
  }

  header nav {
    padding-top: var(--header-height);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    transform: translateZ(-10px) translateY(-100%);
    z-index: 4;
    transition: background-color 0.3s ease-in-out;
  }

  header nav.active {
    display: block;
    transform: translateZ(-1px) translateY(0);
    background-color: #0006;
  }

  header nav ul {
    flex-direction: column;
    justify-content: flex-end !important;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  header nav.active ul {
    flex-direction: column;
    justify-content: flex-end !important;
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  header nav ul li {
    background-color: var(--super-light);
    border-bottom: 2px solid #f5cfdb;
    width: 100%;
    height: var(--header-height);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    transition: background-color .1s ease;
  }

  header nav ul li:hover {
    background-color: var(--light);
    border-color: var(--light);
  }

  header nav ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  header nav ul li:nth-child(1) {
    border-top: 2px solid #f5cfdb;
  }

  header nav .select {
    width: 100%;
    background: transparent;
  }

  header nav .select i.chevron {
    right: 2rem;
    font-size: 1.6rem;
  }

  header nav .select i.earth {
    left: 2rem;
    font-size: 1.6rem;
  }

  header nav .select span {
    text-align: center;
  }
  
  header nav select {
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    text-align: -webkit-center;
    padding: 0;
  }

  section#home {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    row-gap: 4vh;
  }

  #home .photo-container {
    display: none;
  }

  #home .title {
    grid-column: 1;
    grid-row: 1;
    margin: 0 auto;
  }

  #home .title img {
    height: min(40vw, 10rem);
  }

  #home .title h1 {
    font-size: min(18vw, 5rem);
    height: min(40vw, 11rem);
  }

  #home .title h1 span {
    line-height: 1.2;
    margin-top: -1vw;
  }

  #home .text {
    grid-column: 1;
    grid-row: 2;
    font-size: 1.4rem;
  }

  #work {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-block: 6vh;
    min-height: 0;
  }

  #work .text h2 {
    font-size: 4rem;
    margin-top: -1vh;
  }

  #work .text p {
    font-size: 1.4rem;
  }

  #gallery {
    padding-block: 2vh;
  }

  #gallery .galerie {
    height: 55vh;
  }

  #gallery h2 {
    font-size: 4rem;
    margin-bottom: -2vh;
  }

  #contact h2 {
    font-size: 3rem;
  }

  #contact p {
    font-size: 1.2rem;
    margin-top: 1vh;
  }

  .contact-main .input-group {
    display: block;
  }

  .contact-main textarea {
    height: 40vw;
  }

  .contact-main form .half {
    width: 100%;
  }

  .other-contact {
    font-size: 1.2rem;
  }

  footer {
    font-size: .8rem;
  }
}

@media screen and (max-width: 600px) {

  section#home {
    padding: 7vh 4vw 8rem;
  }

  header nav ul li svg {
    display: none;
  }

  #work .text p {
    font-size: 1.4rem;
  }

  #work .text h2 {
    font-size: min(3.6rem, 16vw);
  }

  #contact .contact-main input, #contact .contact-main textarea {
    padding-left: 4vw;
  }
}

@media screen and (max-width: 500px) {
  .title {
    flex-direction: column;
  }

  .title span {
    text-align: center;
    padding: 0
  }

  .title img {
    margin-bottom: 1rem
  }

  #home .title span {
    text-align: center;
    padding: 0;
    margin-bottom: -1.5rem;
    font-size: 5rem;
    line-height: 0;
  }

  #home .title h1 {
    display: block;
    height: fit-content;
    margin-bottom: 2rem;
  }

  section#home {
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    gap: 0;
    max-height: 10000vh;
    height: fit-content;
  }
}

@media screen and (max-width: 360px) {
  #home .title span {
    font-size: 3rem;
  }

  h2 {
    font-size: 3rem !important;
  }

  .other-contact {
    font-size: .8rem;
  }
}